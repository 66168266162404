/* Estilos para el ícono de hamburguesa cuando el menú está activo */
.side-menu__toggler.active {
    color: #fff;
  }
  
  /* Estilos para el menú desplegable */
/* Estilos para el menú desplegable */
.menu-dropdown {
    display: none;
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    z-index: 1000;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    top: 100%; /* Coloca el menú debajo del enlace */
    right: 0; /* Alinea el menú a la derecha */
    width: 200px; /* Ajusta el ancho del menú según tus necesidades */
  }
  
  .menu-dropdown.show {
    display: block;
  }
  
  
  .menu-dropdown ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .menu-dropdown li {
    padding: 10px 0;
  }
  
  .menu-dropdown a {
    text-decoration: none;
    color: #333;
    transition: color 0.3s;
  }
  
  .menu-dropdown a:hover {
    color: #007bff;
  }
  